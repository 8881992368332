import * as React from "react";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled, { css } from "styled-components";
import { black, darkgrey, grey1, white } from "../theme/variables";
import Poi from "./Icons/Poi";
import AccordionIcon from "./Icons/AccordionIcon";

const FAQLink = styled.a`
  color: ${white};

  :hover,
  :focus {
    color: ${grey1};
    text-decoration: underline;
  }
`;

interface FaqModel {
  question: string;
  answer: React.ReactNode;
}

const Title = styled.div`
  color: ${darkgrey};
  font-style: normal;
  font-family: "Gotham";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  padding: 8px 0;
  color: #292b31;
  @media only screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 40px;
  padding-left: 16px;
  color: #ffffff;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -1px;
`;

const SectionNotes = styled.div`
  margin-bottom: 40px;
  padding-left: 16px;
  line-height: 1.3em;
  color: #ffffff;
  font-size: 14px;

  a {
    text-decoration: underline;
    color: #ccc;
  }
`;

const Wrapper = styled.div`
  background-color: ${black};
  box-shadow: 0 8px 60px rgb(150 150 150 / 30%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 25px;

  @media only screen and (max-width: 1023px) {
    margin: 0;
    padding: 0;
    border-radius: unset;
  }
`;

const Container = styled.div`
  padding: 50px 100px 25px;

  @media only screen and (max-width: 1023px) {
    padding: 40px 16px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 50px 40px 25px;
  }
  @media only screen and (min-width: 1600px) {
    padding: 50px 100px 25px;
  }
`;

const Disclaimer = styled.div`
  margin: 0 100px;
  font-size: 12px;
  line-height: 130%;
  color: #bfbfc1;
  opacity: 0.8;
  border: 1px solid red;

  @media only screen and (max-width: 1023px) {
    padding: 0 16px 40px;
    margin: 0;
  }
`;

const BtnChangeLanguage = styled.div`
  display: flex;
  padding: 0 20px 50px 20px;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 768px) {
    display: none;
  }

  .sectionTitle {
    margin: 0;
    font-size: 18px;
    color: #fff;
  }

  .wrapperLanguages {
    position: relative;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    background-color: #292b31;
    border-radius: 30px;
    @media only screen and (min-width: 400px) {
      width: 200px;
    }

    .changeLanguageBtn {
      display: flex;
      justify-content: center;
      padding: 8px 10px;

      p {
        margin-right: 10px;
      }

      .dropdownIcon {
        position: relative;
        height: 8px;
        width: 8px;
        top: 3px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        transition: all 0.3s ease;
      }

      &.active {
        .dropdownIcon {
          top: 6px;
          transform: rotate(-135deg);
        }
      }
    }

    p,
    a {
      padding: 0;
      margin: 0;
      line-height: unset;
      color: #fff;
    }

    a {
      text-decoration: none;
    }
  }

  .languagesList {
    display: none;
    position: absolute;
    top: 45px;
    right: 50%;
    transform: translateX(50%);
    padding: 12px;
    background: #ffffff;
    box-shadow: 0 30px 60px rgb(57 57 57 / 10%);
    border-radius: 12px;

    &.visible {
      display: block;
    }

    a {
      color: #000;
    }
  }
`;

const BoxFaqWrapper = styled.div`
  column-count: 2;
  column-gap: 40px;
  @media only screen and (max-width: 1023px) {
    column-count: 1;
    column-gap: 40px;
  }

  > div {
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 25px;
    break-inside: avoid;
  }

  div.none {
    display: none !important;
  }

  div.otherQuestions {
    background-color: #292b31;

    .title {
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
    }

    .description {
      font-size: 16px;
      line-height: 21px;
      color: #fff;

      a {
        font-weight: bold;
        text-decoration: none;
        color: #fff;
      }
    }
  }
`;

const BoxFaq = styled.div`
  background: #ffffff;
  border-radius: 18px;
  padding: 20px;
  margin-bottom: 25px;

  .MuiPaper-root::before {
    top: unset;
    bottom: 0;
  }

  .MuiPaper-root:first-of-type::before {
    display: block;
  }

  .MuiPaper-root:last-of-type::before {
    display: none;
  }

  .pc-faq__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
    color: #292b31;
    position: relative;
    margin: 0;
    @media only screen and (min-width: 375px) {
      font-size: 18px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  .pc-faq__title__accordion {
    display: none;
  }

  ${(props) =>
    props.icon &&
    css`
      display: flex !important;
      padding: 0 20px;
      align-items: center;
      cursor: pointer;

      .pc-faq__title {
        margin-left: 20px;
        padding: 20px 0;
      }
    `}
  ${(props) =>
    props.accordion &&
    css`
      @media only screen and (max-width: 1023px) {
        .pc-faq__title {
          padding-right: 40px;
        }

        .pc-faq__title__accordion {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          transform: translateY(-20px);
          cursor: pointer;
          display: block;
          right: 0;

          svg {
            width: 100%;
            transform: rotate(0deg);
            transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }

        .pc-faq__title__accordion__content {
          max-height: 0;
          transition: max-height 0.15s ease-out;
          overflow: hidden;
        }
      }
    `}
	${(props) =>
    props.isopen &&
    css`
      .css-tzfe29-MuiPaper-root-MuiAccordion-root.Mui-expanded:before {
        opacity: 1;
      }

      .pc-faq__title__accordion {
        svg {
          transform: rotate(180deg);
        }
      }

      .pc-faq__title__accordion__content {
        max-height: 5000px;
        transition: max-height 0.25s ease-in;
      }
    `}
	.MuiPaper-root {
    &:last-of-type {
      .MuiAccordionSummary-root {
        border-bottom-width: 0;
      }
    }
  }

  .MuiPaper-root.Mui-expanded {
    &:last-of-type {
      .MuiAccordionSummary-root {
        border-bottom-width: 1px;
      }
    }
  }

  .MuiAccordionDetails-root {
    p,
    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #292b31;

      a {
        color: #292b31;
      }
    }
  }

  .MuiPaper-elevation {
    padding-bottom: 20px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .pc-faq__notes {
    font-size: 12px;
    color: #fff;
  }
`;

const Faq: React.FC = (props) => {
  const faqs: FaqModel[] = props.faqList;

  const [expanded, setExpanded] = useState(null);
  const [isOpen, setisOpen] = useState(null);

  const [expandedLanguageBtn, setExpandedLanguageBtn] = useState(false);

  // listen to window resize, send data to parent window (needed to properly display iframe)
  useEffect(() => {
    if (!document || !window || window.self == window.top) {
      // if not inside an iframe don't do anything
      return;
    }

    const sendHeightData = () => {
      const height = document.body?.scrollHeight;
      window.parent?.postMessage(
        { height },
        process.env.GATSBY_ONBOARDING_PORTAL_LINK
      );
    };

    window.addEventListener("resize", sendHeightData);
    sendHeightData();
    // unsubscribe from the listener
    return () => window.removeEventListener("resize", sendHeightData);
  }, []);

  function accordion(faqs, _index) {
    return faqs.map(({ question, answer, dataQuestion }, index) => (
      <Accordion
        key={index}
        sx={{
          color: darkgrey,
          backgroundColor: white,
          padding: "0",
          margin: "0 20px",
          /*borderBottom: "1px solid #292B31;",*/
        }}
        disableGutters
        elevation={0}
        expanded={expanded === _index + "__" + index}
        onChange={() => {
          if (expanded === _index + "__" + index) {
            setExpanded(null);
            setisOpen(null);
          } else {
            setExpanded(_index + "__" + index);
            setisOpen(_index);
          }
        }}
      >
        <AccordionSummary
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header ${dataQuestion ? dataQuestion : ""}`}
          sx={{
            padding: "0",
          }}
          expandIcon={<ExpandMoreIcon sx={{ color: darkgrey }} />}
        >
          <Title>{question}</Title>
        </AccordionSummary>
        <AccordionDetails
          dangerouslySetInnerHTML={{ __html: answer }}
          sx={{
            padding: "0",
            /*borderBottom: "0px solid #292B31;",*/
          }}
        />
      </Accordion>
    ));
  }

  return (
    <>
      <Wrapper id="faq">
        <Container>
          <SectionTitle>
            <p className={"pc-faq__title"}>{props.faqTitleSection}</p>
          </SectionTitle>

          {props.notes && (
            <SectionNotes>
              <p dangerouslySetInnerHTML={{ __html: props.notes }} />
            </SectionNotes>
          )}

          <BoxFaqWrapper>
            <BoxFaq
              className={props.hideComponent ? "none" : ""}
              icon={true}
              onClick={() => {
                props.handleScroll("dealerlocator");
              }}
            >
              <Poi />
              {!props.hideComponent && (
                <p className={"pc-faq__title"}>{props.labelScrollTo}</p>
              )}
            </BoxFaq>
            {faqs.map(({ titleSection, section }, index) => (
              <BoxFaq key={index} accordion={true} isopen={isOpen === index}>
                <p className={"pc-faq__title"}>
                  {titleSection}
                  <a
                    onClick={() => {
                      if (isOpen === index) {
                        setisOpen(null);
                      } else {
                        setisOpen(index);
                      }
                      setExpanded(null);
                    }}
                    className={"pc-faq__title__accordion"}
                  >
                    <AccordionIcon />
                  </a>
                </p>
                <div className={"pc-faq__title__accordion__content"}>
                  {accordion(section, index)}
                </div>
              </BoxFaq>
            ))}

            <BoxFaq className={"otherQuestions"}>
              <p
                className={"title"}
                dangerouslySetInnerHTML={{ __html: props.otherQuestions.title }}
              />
              <p
                className={"description"}
                dangerouslySetInnerHTML={{
                  __html: props.otherQuestions.infoText,
                }}
              />
            </BoxFaq>
          </BoxFaqWrapper>
        </Container>

        {!props.hideComponent && (
          <BtnChangeLanguage>
            <p className={"sectionTitle"}>
              {props.countryCode === "it" ? "Cambia Lingua" : "Change Language"}
            </p>

            {/*<div className={`wrapperLanguages ${expandedLanguageBtn ? 'open' : ''}`} onClick={() => setExpandedLanguageBtn(!expandedLanguageBtn)} >*/}

            <div
              className={"wrapperLanguages"}
              onClick={() => setExpandedLanguageBtn(!expandedLanguageBtn)}
            >
              <div
                className={`changeLanguageBtn ${
                  expandedLanguageBtn && "active"
                }`}
              >
                <p>{props.countryCode === "it" ? "ITA" : "ENG"}</p>
                <div className={"dropdownIcon"}></div>
              </div>

              <div
                className={`languagesList ${expandedLanguageBtn && "visible"}`}
              >
                <a
                  href={
                    props.countryCode === "it"
                      ? `${process.env.GATSBY_SITE_URL}/en`
                      : `${process.env.GATSBY_SITE_URL}/it`
                  }
                >
                  {props.countryCode === "it" ? "ENG" : "ITA"}
                </a>
              </div>
            </div>
          </BtnChangeLanguage>
        )}
      </Wrapper>
    </>
  );
};

export default Faq;
