import * as React from "react";

const Poi: React.FC = () => (
  <svg width="45" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.102 5.437a10.141 10.141 0 0 1 16.18.13M20.81 9.53c.31.98.48 2.02.48 3.09 0 5.21-4.58 11.01-9.11 15.47-.58.57-1.51.58-2.09.01C5.77 23.95 1 17.79 1 12.61c0-1.23.22-2.41.62-3.5"
      stroke="#292B31"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.14 17.046a4.39 4.39 0 1 0 0-8.78 4.39 4.39 0 0 0 0 8.78Z"
      stroke="#292B31"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Poi;
