export const MOBILE_MAX_QUERY = "@media (max-width: 480px)";
export const TABLET_MAX_QUERY = "@media (max-width: 768px)";
export const TABLET_MIN_QUERY = "@media (min-width: 1024px)";

/* Pirelli Official Colours Palette */
export const yellow = "rgb(254, 209, 0)";
export const yellowOpaque = "rgb(254, 209, 0, 0.4)";
export const black = "rgb(0, 0, 0)";
export const blackOpaque = "rgb(0, 0, 0, 0.1)";
export const redPirelli = "#d52b1e";
export const redCare = "rgb(213, 43, 30)";
export const redDark = "rgb(128, 0, 0)";
export const orange = "rgb(228, 108, 10)";
export const blue = "rgb(16, 37, 63)";
export const grey1 = "rgb(166, 166, 166)";
export const grey2 = "rgb(127, 127, 127)";
export const grey3 = "rgb(64, 64, 64)";
export const white = "rgb(255, 255, 255)";
export const darkgrey = "rgb(41, 43, 49)";
/* Custom colors */
export const lightGrey = "rgb(229, 229, 229)";
export const lightGreyOpaque = "rgb(229, 229, 229, 0.2)";
